<template>
  <div class="events-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="eventsListTable"
                             :columns="columnsLabel"
                             :options="options"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getEvents"
                             v-model="data"/>

    <!--  insert event prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertEventPromptStatus"
      @close="insertEventPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertEventBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('events.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertEventPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <insert-event/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <button v-show="false"
            id="InsertEventBTN"
            @click="insertEventPromptStatus = true"/>
  </div>
</template>

<script>
import axios from 'axios'
import CustomIcon from '../../../../components/customIcon/customIcon'
import InsertEvent from '../insert/insertEvent'
import {getEvents} from '../../../../http/requests/events'
import {checkUserPermissions} from '../../../../assets/js/functions'

export default {
  name: 'events',
  components: {InsertEvent, CustomIcon},
  metaInfo () {
    return {
      title: this.$t('events.title')
    }
  },
  data () {
    return {
      insertEventPromptStatus: false,
      requestSent: false,
      types: {
        1: this.$t('events.eventTypes.update'),
        3: this.$t('events.eventTypes.message'),
        5: this.$t('events.eventTypes.report'),
      },
      priority: {
        1: this.$t('events.priority.veryLow'),
        2: this.$t('events.priority.low'),
        3: this.$t('events.priority.medium'),
        4: this.$t('events.priority.high'),
        5: this.$t('events.priority.veryHigh')
      },
      options: {
        id: 'eventsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'createdAt',
          i18n: 'events.list.table.header.sendDate',
          width: 'calc((100% / 8 ))',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true,
          locked: true
        },
        {
          field: 'priority',
          i18n: 'events.list.table.header.priority',
          width: 'calc((100% / 8 ))',
          minWidth: 150,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('events.priority.all'),
              value: '-1'
            },
            {
              label: this.$t('events.priority.veryLow'),
              value: '1'
            },
            {
              label: this.$t('events.priority.low'),
              value: '2'
            },
            {
              label: this.$t('events.priority.medium'),
              value: '3'
            },
            {
              label: this.$t('events.priority.high'),
              value: '4'
            },
            {
              label: this.$t('events.priority.veryHigh'),
              value: '5'
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        {
          field: 'type',
          i18n: 'events.list.table.header.type',
          width: 'calc((100% / 8 ))',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('events.eventTypes.all'),
              value: '-1'
            },
            {
              label: this.$t('events.eventTypes.update'),
              value: '1'
            },
            {
              label: this.$t('events.eventTypes.activity'),
              value: '2'
            },
            {
              label: this.$t('events.eventTypes.message'),
              value: '3'
            },
            {
              label: this.$t('events.eventTypes.warning'),
              value: '4'
            },
            {
              label: this.$t('events.eventTypes.report'),
              value: '5'
            }
          ],
          minWidth: 150,
          sortable: true
          // locked: true,
        },
        {
          field: 'content',
          i18n: 'events.list.table.header.content',
          width: 'calc((100% / 8 ) * 2)',
          minWidth: 300,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        {
          field: 'subject',
          i18n: 'events.list.table.header.subject',
          width: 'calc((100% / 8 ))',
          minWidth: 300,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          align: 'center',
          sortable: true
          // locked: true,
        },
        // {
        //   field: 'creator',
        //   i18n: 'events.list.table.header.sender',
        //   width: 'calc((100% / 8 ) * 2)',
        //   minWidth: 200,
        //   filter: true,
        //   filterTypes: [
        //     {
        //       icon: 'search',
        //       name: 'شامل شود',
        //       i18n: 'draggableTable.filter.types.search',
        //       id: 0
        //     }
        //   ],
        //   align: 'center',
        //   sortable: true
        //   // locked: true,
        // },
        {
          field: 'rowNumber',
          i18n: 'events.list.table.header.rowNumber',
          // sortable: true,
          // locked: true,
          align: 'center',
          width: '70px',
          minWidth: 70,
          sortable: true,
          footer: {}
        }
      ],
      filters: [],
      sorts: [],
      data: [],
      page: 1,
      total_count: null,
      loadingTimer: 0,
      actions: [
        {
          toolbar: [
            {
              id: 'InsertEventBTN',
              color: 'success',
              icon: 'icon-plus',
              iconPack: 'feather',
              permission: 'event.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('auth/setAccessToken')
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getEvents()
  },
  methods: {
    getEvents () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.eventsListTable && this.data.length === 0) this.$refs.eventsListTable.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.eventsListTable && this.data.length > 0) this.$refs.eventsListTable.loadMoreStatus = 'Loading'

            this.getFiltersStatus = true
            getEvents(this.page, this.filters, this.sorts).then((response) => {
              const events = response.data

              events.data.forEach((event) => {
                this.data.push({
                  id: event.id,
                  rowNumber: this.data.length + 1,
                  creator: event.creator ? `${event.creator.name} ${event.creator.family}(${event.creator.role.name})` : 'سیستمی',
                  subject: event.subject,
                  content: event.content,
                  priority: this.priority[event.priority],
                  type: this.types[event.type],
                  createdAt: event.created_at,
                  eventData: event
                })
              })

              this.total_count = events.pagination.total
              this.page = events.pagination.current_page + 1

              if (events.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = events.pagination.total
              }

              if (this.$refs.eventsListTable) this.$refs.eventsListTable.loadMoreStatus = ''

              this.requestSent = false
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {

                if (this.$refs.eventsListTable) this.$refs.eventsListTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              }
            })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilter (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'priority':
          if (filters[key].search.value !== '-1') filters_list.push(`priority=${  filters[key].search.value}`)
          break

        case 'type':
          if (filters[key].search.value !== '-1') filters_list.push(`type=${  filters[key].search.value}`)
          break

        case 'content':
          if (filters[key].search !== '') filters_list.push(`content=${  filters[key].search}`)
          break

        case 'subject':
          if (filters[key].search !== '') filters_list.push(`subject=${  filters[key].search}`)
          break

        case 'creator':
          if (filters[key].search !== '') filters_list.push(`creator=${  filters[key].search}`)
          break

        case 'createdAt':
          if (filters[key].search !== '') filters_list.push(`created_at=${filters[key].search.join('_')}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.total_count = null
      this.filters = filters_list
      this.getEvents()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'priority':
          sorts_list.push(`order[0]=priority,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'content':
          sorts_list.push(`order[0]=content,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'creator':
          sorts_list.push(`order[0]=creator_id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'createdAt':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.total_count = null
      this.sorts = sorts_list
      this.getEvents()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.eventsChanged': {
      handler (val) {
        if (val) {
          this.insertEventPromptStatus = false

          this.data = []
          this.page = 1
          this.getEvents()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
